<template>
  <div class="container">
    <div class="header">
      <div class="title">AI客户开发</div>
    </div>
    
    <div class="content">
      <el-tabs v-model="activeTab" type="card">
        <el-tab-pane label="客户列表" name="customers">
          <div class="tab-content">
            <div class="actions">
              <div class="search-container">
                <el-input
                  v-model="searchQuery"
                  placeholder="搜索客户"
                  prefix-icon="el-icon-search"
                  clearable
                  @keydown.enter.native="handleSearch"
                  style="width: 300px; margin-right: 10px"
                ></el-input>
              </div>
              <div class="button-container">
                <el-button type="primary" @click="addCustomer" style="margin-right: 10px">新增客户</el-button>
              </div>
            </div>
            <el-table :data="filteredCustomerList" style="width: 100%">
              <el-table-column label="客户名称" width="200">
                <template slot-scope="scope">
                  <div>{{ scope.row.contact_lastName }} {{ scope.row.contact_givenName }}</div>
                  <div>{{ scope.row.contact_enName ? scope.row.contact_enName.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : '' }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="contact_email" label="邮箱" width="240"></el-table-column>
              <el-table-column prop="contact_position" label="职位" width="150"></el-table-column>
              <el-table-column prop="contact_discipline" label="学科" width="150"></el-table-column>
              <el-table-column label="单位" width="600">
                <template slot-scope="scope">
                  <div>{{ scope.row.account_name }}</div>
                  <div>{{ scope.row.account_nameEN }}</div>
                </template>
              </el-table-column>
              
              
              
              <el-table-column label="操作" width="150">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="primary"
                    @click="viewCustomerDetails(scope.row)"
                  >详细数据</el-button>
                  
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        
        <el-tab-pane label="开发策略列表" name="tactics">
          <div class="tab-content">
            <div class="actions">
              <el-button type="primary" @click="openTacticDialog()">新增策略</el-button>
            </div>
            <el-table :data="tacticsList" style="width: 100%">
              <el-table-column prop="tactic_name" label="策略名称" width="200"></el-table-column>
              <el-table-column prop="tactic_type" label="策略类型" width="200"></el-table-column>
              <el-table-column prop="tactic_description" label="策略描述"></el-table-column>
              <el-table-column label="操作" width="150">
                <template slot-scope="scope">
                  <el-button size="mini" type="primary" @click="openTacticDialog(scope.row)">编辑</el-button>
                </template>
              </el-table-column>
            </el-table>

            <!-- Tactic Dialog -->
            <el-dialog :title="dialogTitle" :visible.sync="tacticDialogVisible" width="50%">
              <el-form :model="tacticForm" label-width="100px">
                <el-form-item label="策略名称">
                  <el-input v-model="tacticForm.tactic_name"></el-input>
                </el-form-item>
                <el-form-item label="策略类型">
                  <el-input v-model="tacticForm.tactic_type"></el-input>
                </el-form-item>
                <el-form-item label="策略描述">
                  <el-input type="textarea" v-model="tacticForm.tactic_description" rows="4"></el-input>
                </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button @click="tacticDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="saveTactic">确定</el-button>
              </span>
            </el-dialog>
          </div>
        </el-tab-pane>
        
        <el-tab-pane label="营销Post列表" name="marketingPosts">
          <div class="tab-content">
            <div class="actions">
              <el-button type="primary" @click="openPostDialog()">新增Post</el-button>
            </div>
            <el-table :data="marketingPosts" style="width: 100%">
              <el-table-column prop="BU" label="BU" width="120"></el-table-column>
              <el-table-column prop="project_code" label="项目代码" width="120"></el-table-column>
              <el-table-column prop="target_client" label="目标客户" width="150"></el-table-column>
              <el-table-column prop="post_title" label="标题" width="200"></el-table-column>
              <el-table-column prop="post_type" label="类型" width="120"></el-table-column>
              <el-table-column prop="post_status" label="状态" width="120"></el-table-column>
              <el-table-column prop="create_time" label="创建时间" width="180">
                <template slot-scope="scope">
                  {{ new Date(scope.row.create_time*1000).toLocaleString() }}
                </template>
              </el-table-column>
              <el-table-column prop="update_time" label="更新时间" width="180">
                <template slot-scope="scope">
                  {{ new Date(scope.row.update_time*1000).toLocaleString() }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="150">
                <template slot-scope="scope">
                  <el-button size="mini" type="primary" @click="openPostDialog(scope.row)">编辑</el-button>
                </template>
              </el-table-column>
            </el-table>

            <!-- Marketing Post Dialog -->
            <el-dialog :title="postDialogTitle" :visible.sync="postDialogVisible" width="50%">
              <el-form :model="postForm" label-width="100px">
                <el-form-item label="BU">
                  <el-select v-model="postForm.BU">
                    <el-option 
                      v-for="item in ['WAO', 'ICS', 'TNCC','NIM','Review','GYP','SCH_ACT','EAE','FELC']"
                      :key="item"
                      :label="item"
                      :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="项目代码">
                  <el-tag
                    :key="index"
                    v-for="(tag, index) in postForm.project_code"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(index)">
                    {{tag}}
                  </el-tag>
                  <el-input
                    class="input-new-tag"
                    v-if="inputVisible"
                    v-model="inputValue"
                    ref="saveTagInput"
                    size="small"
                    @keyup.enter.native="handleInputConfirm"
                    @blur="handleInputConfirm"
                  >
                  </el-input>
                  <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 添加项目代码</el-button>
                </el-form-item>
                <el-form-item label="目标客户">
                  <el-select v-model="postForm.target_client" multiple>
                    <el-option
                      v-for="item in ['大学','大专', '高中', '初中']"
                      :key="item"
                      :label="item"
                      :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="类型">
                  <el-select v-model="postForm.post_type">
                    <el-option
                      v-for="item in ['最新公告', '产品服务介绍', '使用指导']"
                      :key="item"
                      :label="item"
                      :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="标题">
                  <el-input v-model="postForm.post_title"></el-input>
                </el-form-item>
                <el-form-item label="内容">
                  <el-input type="textarea" v-model="postForm.post_content" rows="4"></el-input>
                </el-form-item>
                
                <el-form-item label="状态">
                  <el-select v-model="postForm.post_status">
                    <el-option
                      v-for="item in ['已发布', '已下架']"
                      :key="item"
                      :label="item"
                      :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="是否置顶">
                  <el-select v-model="postForm.topped">
                    <el-option label="NO" value="NO"></el-option>
                    <el-option label="YES" value="YES"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="项目标签" prop="">
                  <vue-tags-input style="font-size: 16px;width: 55%;" v-model="postForm.project_tags" :tags="tags"
                      @tags-changed="newTags => tags = newTags" />
              </el-form-item>

              </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button @click="postDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="savePost">确定</el-button>
              </span>
            </el-dialog>
          </div>
        </el-tab-pane>
        
        <el-tab-pane label="AI BD邮件列表" name="mailList">
          <div class="tab-content">
            <AiBdMailList filterType="filter" />
          </div>
        </el-tab-pane>
        
        <el-tab-pane label="待信息修补" name="toRepair">
          <div class="tab-content">
            <el-table :data="contactsToRepair" style="width: 100%">
              <el-table-column label="客户名称" width="200">
                <template slot-scope="scope">
                  <div>{{ scope.row.contact_lastName }} {{ scope.row.contact_givenName }}</div>
                  <div>{{ scope.row.contact_enName ? scope.row.contact_enName.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : '' }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="contact_email" label="邮箱" width="240"></el-table-column>
              <el-table-column prop="contact_position" label="职位" width="150"></el-table-column>
              <el-table-column prop="contact_discipline" label="学科" width="150"></el-table-column>
              <el-table-column label="单位" width="300">
                <template slot-scope="scope">
                  <div>{{ scope.row.account_name }}</div>
                  <div>{{ scope.row.account_nameEN }}</div>
                  ({{ scope.row.account_category + ', '+scope.row.account_country}})
                </template>
              </el-table-column>
              <el-table-column prop="contact_supplement" label="补充信息" width="200"></el-table-column>
              <el-table-column label="操作" width="300">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="primary"
                    @click="viewCustomerDetails(scope.row)"
                  >编辑信息</el-button>
                  <el-button
                    size="mini"
                    type="warning"
                    @click="markNotFollowUp(scope.row)"
                  >标记不跟进</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- Edit Dialog -->
    <el-dialog title="编辑信息" :visible.sync="editDialogVisible" width="30%">
      <el-form :model="editForm" label-width="80px">
        <el-form-item label="职位">
          <el-input v-model="editForm.position"></el-input>
        </el-form-item>
        <el-form-item label="学科">
          <el-input v-model="editForm.discipline"></el-input>
        </el-form-item>
        <el-form-item label="补充信息">
          <el-input 
            type="textarea" 
            v-model="editForm.supplement"
            rows="4"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveContactEdit">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { postAccountRequest } from '@/api/assistant'
import AiBdMailList from '@/components/AiBdMailList.vue'

export default {
  name: 'AICustomer',
  components: {
    AiBdMailList
  },
  data() {
    return {
      activeTab: 'customers',
      customerList: [],
      searchQuery: '',
      filteredCustomerList: [],
      tacticsList: [],
      tacticDialogVisible: false,
      tacticForm: {
        tactic_id: '',
        tactic_name: '',
        tactic_type: '',
        tactic_description: ''
      },
      marketingPosts: [],
      postDialogVisible: false,
      postForm: {
        post_id: '',
        BU: '',
        project_code: '',
        target_client: '',
        post_title: '',
        post_content: '',
        post_type: '',
        post_status: '',
        topped:'NO',
        project_tags:[]
      },
      inputVisible: false,
      inputValue: '',
      contactsToRepair: [],
      editDialogVisible: false,
      editForm: {
        contact_id: '',
        position: '',
        discipline: '',
        supplement: ''
      }
    }
  },
  computed: {
    dialogTitle() {
      return this.tacticForm.tactic_id ? '编辑策略' : '新增策略'
    },
    postDialogTitle() {
      return this.postForm.post_id ? '编辑Post' : '新增Post'
    }
  },
  methods: {
    addCustomer() {
      // Implement add customer logic
    },
    editCustomer(row) {
      // Implement edit customer logic
    },
    deleteCustomer(row) {
      // Implement delete customer logic
    },
    async handleSearch() {
      try {
        const response = await postAccountRequest('searchContactsV2', {
          key_word: this.searchQuery
        });
        this.filteredCustomerList = response.data;
        if(response.data.length == 0){
          this.$message.error('未找到相关客户');
        }
      } catch (error) {
        this.$message.error('搜索失败，请重试');
        console.error('Search error:', error);
      }
    },
    viewCustomerDetails(row) {
      this.editForm.contact_id = row._id.$id;
      this.editForm.position = row.contact_position || '';
      this.editForm.discipline = row.contact_discipline || '';
      this.editForm.supplement = row.contact_supplement || '';
      this.editDialogVisible = true;
    },
    async getTacticsList() {
      try {
        const response = await postAccountRequest('getTactics', {});
        this.tacticsList = response.data.data;
      } catch (error) {
        this.$message.error('获取策略列表失败');
        console.error('Get tactics error:', error);
      }
    },

    openTacticDialog(tactic) {
      if (tactic) {
        this.tacticForm = tactic;
        this.tacticForm.tactic_id = tactic._id.$id;
        delete tactic._id;
        this.tacticForm = { ...tactic };
      } else {
        this.tacticForm = {
          tactic_id: '',
          tactic_name: '',
          tactic_type: '',
          tactic_description: ''
        };
      }
      this.tacticDialogVisible = true;
    },

    async getTacticDetails(tacticId) {
      try {
        const response = await postAccountRequest('getTactic', {
          tactic_id: tacticId
        });
        const tactic = response.data;
        this.tacticForm = {
          tactic_id: tactic._id.$id,
          tactic_name: tactic.tactic_name,
          tactic_type: tactic.tactic_type,
          tactic_description: tactic.tactic_description
        };
      } catch (error) {
        this.$message.error('获取策略详情失败');
        console.error('Get tactic details error:', error);
      }
    },

    async saveTactic() {
      try {
        await postAccountRequest('updateTactic', this.tacticForm);
        this.$message.success('保存成功');
        this.tacticDialogVisible = false;
        this.getTacticsList();
      } catch (error) {
        this.$message.error('保存失败');
        console.error('Save tactic error:', error);
      }
    },

    async getMarketingPosts() {
      try {
        const response = await postAccountRequest('getMarketingPosts', {});
        this.marketingPosts = response.data.data;
      } catch (error) {
        this.$message.error('获取营销Post列表失败');
        console.error('Get marketing posts error:', error);
      }
    },

    openPostDialog(post) {
      if (post) {
        this.postForm = { ...post };
        this.postForm.post_id = post._id.$id;
      } else {
        this.postForm = {
          post_id: '',
          BU: '',
          project_code: '',
          target_client: '',
          post_title: '',
          post_content: '',
          post_type: '',
          post_status: ''
        };
      }
      this.postDialogVisible = true;
    },

    async savePost() {
      try {
        await postAccountRequest('updateMarketingPost', this.postForm);
        this.$message.success('保存成功');
        this.postDialogVisible = false;
        this.getMarketingPosts();
      } catch (error) {
        this.$message.error('保存失败');
        console.error('Save marketing post error:', error);
      }
    },

    handleClose(index) {
      this.postForm.project_code.splice(index, 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        if (!Array.isArray(this.postForm.project_code)) {
          this.postForm.project_code = [];
        }
        this.postForm.project_code.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },

    async getContactsToRepair() {
      try {
        const response = await postAccountRequest('getContactsToRepair', {});
        this.contactsToRepair = response.data.data;
      } catch (error) {
        this.$message.error('获取待修补联系人列表失败');
        console.error('Get contacts to repair error:', error);
      }
    },

    async markNotFollowUp(row) {
      try {
        await this.$confirm('确定要将该客户标记为不跟进吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });
        
        const response = await postAccountRequest('markContactNotFollowUp', {
          contact_id: row._id.$id
        });
        
        this.$message.success('标记成功');
        
      } catch (error) {
        if (error !== 'cancel') {
          this.$message.error('操作失败，请重试');
          console.error('Mark not follow up error:', error);
        }
      }
    },

    async saveContactEdit() {
      try {
        await postAccountRequest('repairContact', {
          contact_id: this.editForm.contact_id,
          position: this.editForm.position,
          discipline: this.editForm.discipline,
          supplement: this.editForm.supplement
        });
        this.$message.success('保存成功');
        this.editDialogVisible = false;
        // Refresh the contacts list
        this.getContactsToRepair();
      } catch (error) {
        this.$message.error('保存失败');
        console.error('Save contact edit error:', error);
      }
    }
  },
  created() {
    this.filteredCustomerList = this.customerList;
    this.getTacticsList();
    this.getMarketingPosts();
    this.getContactsToRepair();
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 20px;
  height: 100vh;
  background: #f0f2f5;

  .header {
    margin-bottom: 20px;
    
    .title {
      font-size: 24px;
      font-weight: bold;
      color: #333;
    }
  }

  .content {
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    
    .tab-content {
      padding: 20px 0;

      .actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        
        .search-container {
          flex: 1;
        }
        
        .button-container {
          margin-left: 10px;
        }
      }
    }
  }
}

.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style> 